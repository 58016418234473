import React, {useEffect, useState} from 'react';
import {InfiniteScroll, PullToRefresh, List, Ellipsis, NavBar, SwipeAction, Dialog, Empty} from 'antd-mobile';
import {FillinOutline,} from 'antd-mobile-icons';
import {useNavigate, useParams} from "react-router-dom";
import {deletePropertyLog, getPropertyInfo, getPropertyLogList} from "../../services/property";
import toast from "../../utils/toast";
import response from "../../utils/response";

import './loglist.css';

const PropertyLogList = () => {
    const navigate = useNavigate();
    const [info, setInfo] = useState({});
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const perPage = 15;

    const { property_id } = useParams();

    function getInfo() {
        getPropertyInfo(property_id).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                setInfo(data);
            }
        });
    }

    function getData(isRefresh) {
        setHasMore(false);

        getPropertyLogList(property_id, {
            page: isRefresh ? 1 : page,
            per_page: perPage
        }).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                setHasMore(data.current_page < data.last_page);

                if (isRefresh) {
                    setPage(2);
                    setList(data.data);
                } else {
                    setPage(() => data.current_page + 1);
                    setList(val => [...val, ...data.data]);
                }
            }
        });
    }

    useEffect(() => {
        getInfo();
        getData(true);
        // eslint-disable-next-line
    }, []);

    function handleGoBack() {
        navigate(-1);
    }

    const toEditPage = () => {
        navigate('/property/edit/' + property_id);
    };
    
    const navBarRight = (
        <div style={{ fontSize: 24 }}>
            <FillinOutline onClick={toEditPage} />
        </div>
    );

    function handleDelete(id) {
        Dialog.confirm({
            content: '确定要删除吗？',
            onConfirm: async () => {
                deletePropertyLog(id).then(res => {
                    const {code} = res || {};

                    if (response.isSuccess(code)) {
                        toast.success('删除成功');

                        const newList = list.filter(item => item.id !== id);
                        setList(newList);
                    }
                });
            },
        });
    }

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                    right={navBarRight}
                >
                    {info.name}
                </NavBar>
            </div>
            <div className="page-body page-bg">
                <PullToRefresh
                    onRefresh={() => getData(true)}
                >
                    {list.length > 0 ? (
                        <>
                            <List>
                                {list.map(item => (
                                    <SwipeAction
                                        key={item.id}
                                        rightActions={[
                                            {
                                                key: 'delete',
                                                text: '删除',
                                                color: 'danger',
                                                onClick: () => handleDelete(item.id),
                                            },
                                        ]}
                                    >
                                        <List.Item
                                            key={item.id}
                                            extra={
                                                <div>
                                                    <div className="property-loglist-amount">{item.amount >= 0 ? '+' : ''}{item.amount}</div>
                                                    <div className="property-loglist-after">{item.after}</div>
                                                </div>
                                            }
                                            description={item.create_time}
                                        >
                                            <div className="property-loglist-memo">
                                                <Ellipsis direction='end' content={item.memo} />
                                            </div>
                                        </List.Item>
                                    </SwipeAction>
                                ))}
                            </List>
                            <InfiniteScroll loadMore={() => getData(false)} hasMore={hasMore} />
                        </>
                    ) : (
                        <List>
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        </List>
                    )}
                </PullToRefresh>
            </div>
        </div>
    )
};

export default PropertyLogList;